export interface TimeFilterProps {
  startTime: Date;
  endTime: Date;
}

export const setTimeFilterByCurrentTime = (length: number) => {
  const timeFilter: TimeFilterProps = {
    endTime: new Date(),
    startTime: new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * length),
  };
  return timeFilter;
};

export const convertDateToEpochTimeInSeconds = (date: Date) =>
  Math.round(date.getTime() / 1000);
