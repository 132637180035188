import React, { useState } from 'react';
import { KatButton, KatLabel } from '@amzn/katal-react';
import DatePicker from 'react-datepicker';
import { TimeFilterProps } from 'src/utils/timeFilterUtils/timeFilterUtils';

export function TimeFilter({
  initTimeRange,
  changeTimeRange,
}: {
  initTimeRange: TimeFilterProps;
  changeTimeRange: any;
}) {
  const [startTime, setStartTime] = useState(initTimeRange.startTime);
  const [endTime, setEndTime] = useState(initTimeRange.endTime);
  const isTimeRangeValid = (startTime: Date, endTime: Date) =>
    startTime <= endTime;

  const submit = () => {
    if (isTimeRangeValid(startTime, endTime)) {
      const timeRange: TimeFilterProps = {
        startTime,
        endTime,
      };
      changeTimeRange(timeRange);
    }
  };

  return (
    <div className="time-filter-background">
      {isTimeRangeValid(startTime, endTime) ? (
        ''
      ) : (
        <span className="hints">* Time range is invalid</span>
      )}
      <div className="time-filter-container">
        <div className="time-filter-datepicker-container">
          <KatLabel text="Start Time" className="datepicker-label" />
          <DatePicker
            selected={startTime}
            onChange={(time: Date) => setStartTime(time)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            className="datepicker"
          />
        </div>
        <div className="time-filter-datepicker-container">
          <KatLabel text="End Time" className="datepicker-label" />
          <DatePicker
            selected={endTime}
            onChange={(time: Date) => setEndTime(time)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            className="datepicker"
          />
        </div>
        <KatButton
          className="time-filter-button"
          label="Search"
          variant="primary"
          disabled={!isTimeRangeValid(startTime, endTime)}
          onClick={() => submit()}
        />
      </div>
    </div>
  );
}
