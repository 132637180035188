import React, { useContext, useEffect } from 'react';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { OperatorFlowTable } from 'src/components/OperatorFlowTable/OperatorFlowTable';
import { TimeFilter } from 'src/components/TimeFilter/TimeFilter';
import {
  convertDateToEpochTimeInSeconds,
  setTimeFilterByCurrentTime,
  TimeFilterProps,
} from 'src/utils/timeFilterUtils/timeFilterUtils';
import { ApiContext } from 'src/context/apiContext';
import { useParams } from 'react-router-dom';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation/BreadcrumbNavigation';

export function OperatorFlowsPage() {
  const { state, dispatch } = useContext(ApiContext);
  const { modelArtifactId } = useParams<Record<string, string>>();
  const initTimeRange = setTimeFilterByCurrentTime(30);

  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );
  const currentModelVersionId = state.search.curModelVersionId;
  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === currentModelVersionId,
  );

  useEffect(() => {
    dispatch({
      type: 'setCurrentModelArtifactId',
      payload: {
        modelArtifactId,
        startTime: convertDateToEpochTimeInSeconds(initTimeRange.startTime),
        endTime: convertDateToEpochTimeInSeconds(initTimeRange.endTime),
      },
    });
  }, []);

  const changeTimeRange = (timeFilter: TimeFilterProps) => {
    dispatch({
      type: 'setCurrentModelArtifactId',
      payload: {
        modelArtifactId: state.search.curModelArtifactId,
        startTime: convertDateToEpochTimeInSeconds(timeFilter.startTime),
        endTime: convertDateToEpochTimeInSeconds(timeFilter.endTime),
      },
    });
  };

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              {
                display: applicationDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
              },
              {
                display: modelVersionDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}`,
              },
              {
                display: `Operator Flows (${modelArtifactId})`,
              },
            ]}
          />
          <h1>Operator Flows</h1>
        </>
      }
    >
      <TimeFilter
        initTimeRange={initTimeRange}
        changeTimeRange={changeTimeRange}
      />
      <OperatorFlowTable />
    </ContentLayout>
  );
}
