import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/context/userContext';
import { fetchMetricsByModelArtifactId } from 'src/hooks/useDataQualityMetrics/fetchDataQualityMetricsData/fetchByModelArtifactId/fetchMetricsByModelArtifactId';
import {
  convertDateToEpochTimeInSeconds,
  TimeFilterProps,
} from 'src/utils/timeFilterUtils/timeFilterUtils';
import { fetchMetricsByTrainingJobId } from 'src/hooks/useDataQualityMetrics/fetchDataQualityMetricsData/fetchByTrainingJobId/fetchMetricsByTrainingJobId';

export const useDataQualityMetrics = (
  modelArtifactId: string | undefined,
  trainingJobId: string | undefined,
  initTimeRange: TimeFilterProps,
) => {
  const user = useContext(UserContext);
  const [timeFilter, setTimeFilter] = useState(initTimeRange);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<ModelMetric[] | null>(null);

  useEffect(() => {
    if (!modelArtifactId && !trainingJobId) return;
    if (modelArtifactId && trainingJobId) return;
    setLoading(true);

    if (modelArtifactId) {
      fetchMetricsByModelArtifactId(
        modelArtifactId,
        convertDateToEpochTimeInSeconds(timeFilter.startTime).toString(),
        convertDateToEpochTimeInSeconds(timeFilter.endTime).toString(),
        user.token,
      )
        .then((data: ModelMetric[]) => {
          setLoading(false);
          setError(null);
          setData(data);
        })
        .catch((err) => {
          setLoading(false);
          setData(null);
          setError(err);
        });
    }
    if (trainingJobId) {
      fetchMetricsByTrainingJobId(
        trainingJobId,
        convertDateToEpochTimeInSeconds(timeFilter.startTime).toString(),
        convertDateToEpochTimeInSeconds(timeFilter.endTime).toString(),
        user.token,
      )
        .then((data: ModelMetric[]) => {
          setLoading(false);
          setError(null);
          setData(data);
        })
        .catch((err) => {
          setLoading(false);
          setData(null);
          setError(err);
        });
    }
  }, [modelArtifactId, trainingJobId, timeFilter, user.token]);

  return [loading, error, data, setTimeFilter] as const;
};
